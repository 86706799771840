import type { DeepReadonly, Ref } from 'vue'

export type AlertType = 'success' | 'danger' | 'info' | 'warning'

export interface AlertsState {
  alertType: AlertType
  isAlertVisible: boolean
  shouldPersistThroughNav: boolean
  alertTimeout: number
  alertMessage: string
}

export type ShowAlert = (params: Partial<AlertsState>) => void

export interface UseAlerts {
  alerts: DeepReadonly<Ref<AlertsState>>
  showAlert: ShowAlert
  hideAlert: () => void
}

export function useAlerts(): UseAlerts {
  let timeout: NodeJS.Timeout

  const alerts = useState<AlertsState>('alerts', () => ({
    alertType: 'info',
    isAlertVisible: false,
    shouldPersistThroughNav: false,
    alertTimeout: 3000,
    alertMessage: '',
  }))

  const showAlert: ShowAlert = params => {
    alerts.value = {
      ...alerts.value,
      ...params,
      isAlertVisible: true,
    }

    if (alerts.value.alertTimeout) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        alerts.value = {
          ...alerts.value,
          isAlertVisible: false,
          shouldPersistThroughNav: false,
        }
      }, alerts.value.alertTimeout)
    }
  }

  const hideAlert = () => {
    alerts.value = {
      ...alerts.value,
      isAlertVisible: false,
      shouldPersistThroughNav: false,
    }
  }

  return {
    alerts: readonly(alerts),
    showAlert,
    hideAlert,
  }
}
